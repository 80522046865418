<template>
    <div class="clockin">
        <div class="chang">{{mycontent.name}}</div>
        <img class="zanj" @click="zanj" src="https://ppyos.xijiuyou.com/202304/my-join-record.png" alt=""/>
        <img class="canyu zanj" @click="guidelistbtn(0)" src="https://ppyos.xijiuyou.com/202304/how-to-join.png" alt="" />
        <div class="headtime">
            <p v-if="mycontent.beginDate && mycontent.beginTime && mycontent.endTime">{{mycontent.beginDate.split("-")[1].split("-")[0]+"月"}}{{mycontent.beginDate.split("-")[2]+"日"}}{{mycontent.beginTime.split(":")[0]+":"+mycontent.beginTime.split(":")[1]}}-{{mycontent.endTime.split(":")[0]+":"+mycontent.endTime.split(":")[1]}}打卡</p>
            <p>可瓜分奖金</p>
        </div>
        <div class="mymoney">
            {{mycontent.poolMoney}}<span>元</span>
        </div>
        <div class="application">{{mycontent.joinPerson}}人已报名</div>
        <img class="listimg" src="https://ppyos.xijiuyou.com/202304/introduce-lc.png" alt="" />

        <img class="listbtn" @click="application" :style="mycontent.isSignIn?'':'margin-bottom:19px;'" :src="mycontent.buttonType == 2?'https://ppyos.xijiuyou.com/202304/sign-btn.png':mycontent.buttonType == 3?'https://ppyos.xijiuyou.com/202304/click-to-sign-btn.png':'https://ppyos.xijiuyou.com/202304/joined-btn-img.png'" alt="" />
        <div class="applicationsucc" v-if="mycontent.isSignIn">已打卡，8点后瓜分奖金，奖金以彩贝形式发放</div>

        <div class="myclockin">
            <img :src="mycontent.avatar?mycontent.avatar:'https://thirdwx.qlogo.cn/mmopen/vi_32/6Kgt3vHthv4LZVUxImib8FRYWM2GFk2sj9pOdyULQ7YDN86c4kZxQXVusFCAtn8pgYqkbJZwdauibEor8S0RbojA/132'" alt="">
            <div>
                <!-- mycontent.isJoined? -->
                <p>{{ myclockcontent(mycontent)}}</p>
                <p>今日打卡</p>
            </div>
            <div>
                <p>{{mycontent.totalDay}}天</p>
                <p>累计打卡</p>
            </div>
            <div>
                <p>{{ mycontent.totalAward }}元</p>
                <p>累计奖金</p>
            </div>
        </div>
        <div class="listcontent">
            <img class="listcontenthead" src="https://ppyos.xijiuyou.com/202304/rank-yesterday.png" alt="">
            <div class="listcontentmoney">
                <p><span>总奖金</span> {{allAward}}元</p>
                <p>成功 {{successCount}}人</p>
                <p>失败 {{failCount}}人</p>
            </div>
            <div class="listcontent-list">
                <div class="listcontent-list-head">
                    <div :class="checkindex===1?'checkindex':''" @click="YesterdayRankingList(1)">分得最多</div>
                    <div :class="checkindex===2?'checkindex':''" @click="YesterdayRankingList(2)">打卡最早</div>
                    <div :class="checkindex===3?'checkindex':''" @click="YesterdayRankingList(3)">坚持最久</div>
                </div>
                <div class="listcontent-list-lmy" v-if="myRanking">
                    <div>
                        <div>
                            <img :src="myRanking.photo?myRanking.photo:'https://thirdwx.qlogo.cn/mmopen/vi_32/6Kgt3vHthv4LZVUxImib8FRYWM2GFk2sj9pOdyULQ7YDN86c4kZxQXVusFCAtn8pgYqkbJZwdauibEor8S0RbojA/132'" alt="">
                            <div class="paim">{{myRanking.ranking}}</div>
                        </div>
                        <p>(我){{myRanking.nickname}}</p>
                    </div>
                    <div>{{checkindex===1?`分得${myRanking.desc.toFixed(2)}元`:checkindex===2?daytimeys(myRanking.desc)+'打卡':('累计'+myRanking.desc+'天')}}</div>
                </div>
                <div class="listcontent-list-list">
                    <div v-for="(item,index) in rankingList" :key="index">
                        <div>
                            <div>
                                <img :src="item.photo?item.photo:'https://thirdwx.qlogo.cn/mmopen/vi_32/6Kgt3vHthv4LZVUxImib8FRYWM2GFk2sj9pOdyULQ7YDN86c4kZxQXVusFCAtn8pgYqkbJZwdauibEor8S0RbojA/132'" alt="">
                                <img v-if="index<3" :src="`https://ppyos.xijiuyou.com/202304/rank-${index+1}.png`" alt="">
                                <div v-else class="paim">{{item.ranking}}</div>
                            </div>
                            <p>{{item.nickname}}</p>
                        </div>
                        <div :class="index<3?'orgins':''">{{checkindex===1?`分得${item.desc.toFixed(2)}元`:checkindex===2?daytimeys(item.desc)+'打卡':('累计'+item.desc+'天')}}</div>
                    </div>
                </div>
            </div>
        </div>
        <xwpopup  :isshowpopup="show" @close="closeshow()" :hiddenClose="true" >
            <div class="popupcontent">
                <div class="popupcontent-head">
                    <p>
                        <img src="https://ppyos.xijiuyou.com/202304/join-txt.png" alt="">
                       <span v-if="mycontent.name"> {{mycontent.name.split("场")[0]}}</span>
                        <img src="https://ppyos.xijiuyou.com/202304/zqdk-c-txt.png" alt="">
                    </p>
                    <img src="https://ppyos.xijiuyou.com/202304/introduce-or-txt.png" alt="">
                    <p>参赛券越多，瓜分奖金越多</p>
                    <img class="popupcontent-btn" @click="closeshow" src="https://ppyos.xijiuyou.com/202304/close-icon.png" alt="" />
                </div>
                <div class="popupcontent-listcheck">
                    <div class="popupcontent-listcheck-head">
                        <div v-for="(item,index) in tickets" :key="index" :class="listchecks.payId===item.payId ?'popupcontent-listcheck-head-check':''" @click="listchecksbtn(item)">
                            <p v-html="item.title"></p>
                            <p>{{item.note}}</p>
                            <p><img :src="listchecks.payId===item.payId?'https://ppyos.xijiuyou.com/202304/checked-icon-or.png':'https://ppyos.xijiuyou.com/202304/checked-icon-white.png'" alt=""></p>
                        </div>
                    </div>
                    <div class="popupcontent-listcheck-xian"></div>
                    <div class="certificate">
                        <p>参赛券共计</p>
                        <p><span>{{ listchecks.count }}</span>张</p>
                    </div>
                </div>
                <div class="checktable">
                    <div class="checktable-check" v-for="(item,index) in payTypes" :key="index" @click="checkbtn(item)">
                        <p>
                            <img :src="item.type===1?'https://ppyos.xijiuyou.com/202304/wechat-small-icon.png':listchecks.costForCoin > item.coin?'https://ppyos.xijiuyou.com/202304/coin-icon.png':'https://ppyos.xijiuyou.com/202205/msg-icon.jpg'" alt="" />
                            <span :style="listchecks.costForCoin > item.coin?'color:#999999;':''">{{item.title}}</span>
                            <span v-if="item.coin">(剩余{{item.coin}}彩贝)</span>
                        </p>
                        <div class="caibei" v-if="item.type === 3 && listchecks.costForCoin > item.coin" @click="gocrash(item.jump)">
                            兑换彩贝
                        </div>
                        <div class="checktable-checkor" v-else :style="itemtype.type !== item.type?'background:#ffffff;border:1px solid #eeeeee;':''">
                            <img v-if="itemtype.type === item.type" src="https://ppyos.xijiuyou.com/202304/checked-icon-white.png" alt="">
                        </div>
                    </div>
                    <!-- <div  class="checktable-check">
                        <p>
                            <img src="https://ppyos.xijiuyou.com/202304/wechat-small-icon.png" alt="" />
                            <span>微信支付</span>
                        </p>
                        <div class="checktable-checkor">
                            <img src="https://ppyos.xijiuyou.com/202304/checked-icon-white.png" alt="">
                        </div>
                    </div> -->
                </div>
                <div class="guize">
                    <div @click="tongy = !tongy" :style="tongy?'border:0;':''">
                        <img v-if="tongy" src="https://ppyos.xijiuyou.com/202304/ratio-icon.png" alt="">
                    </div>
                    我已阅读并同意 <span @click="hdguize(0)">活动规则</span> 及 <span @click="hdguize(1)">用户协议</span>
                </div>
                <div class="bottom-zhif" v-if="itemtype.type === 3">
                    <div>
                        <p>共计<span>{{listchecks.costForCoin}}</span> <img src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt=""></p>
                        <p>得{{listchecks.count}}张参赛券</p>
                    </div>
                    <img @click="zhifu" src="https://ppyos.xijiuyou.com/202304/pay-btn-img.png" alt="">
                </div>
                <div class="bottom-zhif" v-if="itemtype.type === 1">
                    <div>
                        <p>共计<span>{{listchecks.cost}}</span> <span class="yuan">元</span></p>
                        <p>得{{listchecks.count}}张参赛券</p>
                    </div>
                    <img  @click="zhifu" src="https://ppyos.xijiuyou.com/202304/pay-btn-img.png" alt="">
                </div>
            </div>
        </xwpopup>
        <xwpopup  :isshowpopup="showpopup" @close="closeshowpopup()" :hiddenClose="false" >
            <div class="showpopup">
                <!-- https://ppyos.xijiuyou.com/202304/join-success-img.png -->
                <!-- https://ppyos.xijiuyou.com/202304/dk-sign-success.png -->
                <!-- https://ppyos.xijiuyou.com/202304/dk-sign-fail.png -->
                <img class="showpopuphead"
                :src="clockbtn.status === 0
                ?'https://ppyos.xijiuyou.com/202304/coin-success-img.png'
                :clockbtn.status === 1
                ?'https://ppyos.xijiuyou.com/202304/dk-sign-fail.png'
                :joinsucc?
                'https://ppyos.xijiuyou.com/202304/join-success-img.png'
                :'https://ppyos.xijiuyou.com/202304/dk-sign-success.png'
                "
                alt="">
                <p class="showpopupsucc" v-if="joinsucc">获得 <span>{{joincost}}张</span> 参赛券，1张参赛券可瓜分1份奖金，记得在 <span v-if="mycontent.beginDate">{{mycontent.beginDate.split("-")[1].split("-")[0]+"月"}}{{mycontent.beginDate.split("-")[2]+"日"}}{{mycontent.beginTime.split(":")[0]+":"+mycontent.beginTime.split(":")[1]}}-{{mycontent.endTime.split(":")[0]+":"+mycontent.endTime.split(":")[1]}}</span>来本页面打卡哦</p>
                <p v-if="clockbtn.status !== 1 && !joinsucc && clockbtn.status !== 0" class="showpopupbtn">稍后打卡截止将 <span>自动结算</span> <br/> 您可以继续报名下一场活动</p>
                <p class="showpopupshib" v-if="clockbtn.status === 1">{{clockbtn.name}}没有打卡噢，请再接再厉</p>
                <div  v-if="clockbtn.status === 0">
                    <p>{{clockbtn.name}}打卡成功 <br/> 恭喜分得奖金</p>
                    <p>{{clockbtn.award}} <span>元</span></p>
                    <p>折合彩贝{{clockbtn.coin}}</p>
                </div>
                <img @click="closeshowpopup()" src="https://ppyos.xijiuyou.com/202304/confirm-btn-img.png" alt="">
                <p v-if="clockbtn.status === 0">奖励已于今日08:10发放至彩贝账户，可提现</p>
            </div>
        </xwpopup>
        <div class="guide" v-show="guidelist<=3">
            <img v-if="guidelist === 0" @click="guidelistbtn(1)" src="https://ppyos.xijiuyou.com/202304/guide-1.png" alt=""/>
            <img v-if="guidelist === 1" @click="guidelistbtn(2)" src="https://ppyos.xijiuyou.com/202304/guide-2.png" alt=""/>
            <img v-if="guidelist === 2" @click="guidelistbtn(3)" src="https://ppyos.xijiuyou.com/202304/guide-3.png" alt=""/>
            <img v-if="guidelist === 3" @click="guidelistbtn(4)" src="https://ppyos.xijiuyou.com/202304/guide-4.png" alt=""/>
        </div>
    </div>
</template>
<script>
import xwpopup from "../../components/Popupbox.vue";
import clockin from "../../api/clockin";
import dayjs from "dayjs";
import { Toast } from "vant";
import payUtils from "../../utils/payUtil";
export default{
    components:{
        xwpopup
    },
    data(){
        return{
            show:false,
            showpopup:false,
            allAward:0,
            failCount:0,
            successCount:0,
            mycontent:{
                canJoin:true
            },
            checkindex:1,
            myRanking:null,
            rankingList:[],
            clockbtn:{},
            payTypes:[],
            tickets:[],
            itemtype:{},
            listchecks:{},
            tongy:true,
            guidelist:4,
            joinsucc:false,
            joincost:0,
        }
    },
    mounted(){
        if(localStorage.getItem("guidelist")){
            this.guidelist = Number(localStorage.getItem("guidelist"));
        }else{
            localStorage.setItem("guidelist",0);
            this.guidelist = Number(localStorage.getItem("guidelist"));
        }
        this.yesterdaySituation();
        this.YesterdayRankingList(1);
        this.todayInfo();
        this.notice();
    },
    methods:{
        myclockcontent(item){
            if(item.statusType == 1){
                return "尚未参加";
            }else if(item.statusType == 2){
                return "已报名";
            }else if(item.statusType == 3){
                return "已打卡";
            } else {
                return "尚未参加";
            }
        },
        hdguize(index){
            this.$router.push({
                path:"/clockinguiz?guize="+index
            })
        },
        guidelistbtn(index){
            localStorage.setItem("guidelist",index);
            this.guidelist = Number(localStorage.getItem("guidelist"));
        },
        zhifu(){
            this.show = false;
            const that = this;
            if(this.tongy){
                clockin.makeOrder({payId:this.listchecks.payId}).then(res=>{
                    this.joincost = res.data.times
                    if(this.itemtype.type === 1){
                        payUtils.wechatPay(res.data.orderNumber,res.data.payInfoService).then(()=>{
                            that.yesterdaySituation();
                            that.todayInfo();
                            that.notice();
                            that.showpopup = true;
                            that.joinsucc = true;
                        })
                    }else{
                        clockin.requestPay({orderId:res.data.orderNumber,payInfoService:res.data.payInfoService}).then(()=>{
                            that.yesterdaySituation();
                            that.todayInfo();
                            that.notice();
                            that.showpopup = true;
                            that.joinsucc = true;
                        })
                    }
                })
            }else{
                Toast("请勾选并阅读活动规则以及用户协议！");
            }
        },
        listchecksbtn(item){
            const that = this;
            that.listchecks =item;
            if(item.costForCoin > that.itemtype.coin){
                that.payTypes.map(res_=>{
                    if(res_.type=== 1){
                        that.itemtype = res_
                    }
                })
            }
        },
        gocrash(gocrash){
            this.$router.push({
                path:gocrash
            })
        },
        checkbtn(type){
            const that = this;
            if(type.type === 3 && that.listchecks.costForCoin > type.coin){
                return false;
            }
            that.itemtype = type;
        },
        payInfo(){
            clockin.payInfo().then(res=>{
                this.payTypes = res.data.payTypes;
                this.tickets = res.data.tickets;
                this.listchecks = res.data.tickets[0];
                
                // eslint-disable-next-line no-unused-vars
                let payTypes1 = {}; 
                // eslint-disable-next-line no-unused-vars
                let payTypes2 = {};
                res.data.payTypes.map(res_=>{
                    if(res_.type=== 3){
                        payTypes1 = res_;
                    }else{
                        payTypes2 = res_
                    }
                })
                if(payTypes1.coin  >= this.listchecks.costForCoin){
                    this.itemtype = payTypes1;
                }else{
                    this.itemtype = payTypes2;
                }
                
            })
        },
        application(){
            if(this.mycontent.canSignIn){
                clockin.morningsign().then(()=>{
                    this.showpopup =true;
                })
            }else if(this.mycontent.canJoin){
                this.show = true;
                this.payInfo();
            }

        },
        daytimeys(item){
            return dayjs(item).format("HH:mm:ss");
        },
        notice(){
            clockin.notice().then(res=>{
                this.clockbtn = res.data;
                if(res.data.status != 2){
                    this.showpopup = true;
                }
            })
        },
        todayInfo(){
            clockin.todayInfo().then(res=>{
                this.mycontent = res.data
            })
        },
        YesterdayRankingList(type){
            this.checkindex = type;
            this.rankingList = [];
            this.myRanking = null;
            clockin.YesterdayRankingList({type:type}).then(res=>{
                this.myRanking = res.data.myRanking;
                this.rankingList = res.data.rankingList;
            })
        },
        yesterdaySituation(){
            clockin.yesterdaySituation().then(res=>{
                this.allAward = res.data.allAward;
                this.failCount = res.data.failCount;
                this.successCount = res.data.successCount;
            })
        },
        closeshowpopup(){
            this.showpopup = !this.showpopup;
            this.joinsucc = false;
            this.yesterdaySituation();
            this.todayInfo();
            this.notice();
        },
        closeshow(){
            this.show = !this.show;
        },
        zanj(){
            this.$router.push({
                name:"clocklist"
            })
        },
    }
}
</script>
<style lang="less" scoped>
.clockin{
    width: 100%;
    min-height: 100vh;
    background: url("https://ppyos.xijiuyou.com/202304/rank-bg.png") no-repeat;
    background-size: 100%;
    background-color: rgba(122, 212, 137, 1);
    padding-top: 7px;
    padding-bottom: 20px;
    text-align: center;
    .zanj{
        width: 49px;
        height: 49px;
        position: absolute;
        right: 15px;
        top:12px;
    }
    .canyu{
        top:70px;
    }
    .chang{
        width: 54px;
        height: 24px;
        background: #FFFFFF;
        border-radius: 0px 12px 12px 0px;
        position: absolute;
        left: 0;
        top:20px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        text-align: center;
        line-height: 24px;
    }
    .headtime{
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #003264;
        text-align: center;
        margin-bottom: 5px;
    }
    .mymoney{
        font-size: 40px;
        font-family: DINOffcPro-Bold, DINOffcPro;
        font-weight: bold;
        color: #003264;
        text-align: center;
        span{
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #003264;
        }
    }
    .application{
        width: 133px;
        height: 20px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        margin: 3px auto 12px;
        text-align: center;
        line-height: 20px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #003264;
    }
    .listimg{
        width: calc(100% - 48px);
        margin-bottom: 17px;
    }
    .listbtn{
       width: 266px;
       margin-bottom: 5px;
    }
    .myclockin{
        width: calc(100% - 28px);
        background: #ffffff;
        border-radius: 15px;
        margin: 0 auto 12px;
        display: flex;
        justify-content: space-around;
        padding: 19px 13px;
        img{
            width: 48px;
            height: 48px;
            border-radius: 50%;
        }
        div{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
        }
        div:nth-child(2){
            p:nth-child(1){
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom:10px;
            }
        }
        div:nth-child(3){
            p:nth-child(1){
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                margin-bottom:10px;
            }
        }
        div:nth-child(4){
            p:nth-child(1){
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FF6400;
                margin-bottom:10px;
            }
        }
    }
    .applicationsucc{
        width: 314px;
        height: 27px;
        background: rgba(0,0,0,0.3);
        border-radius: 15px;
        backdrop-filter: blur(4px);
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        text-align: center;
        line-height: 27px;
        margin: 0 auto 12px;
    }
    .listcontent{
        width: calc(100% - 28px);
        border-radius: 15px;
        margin: 0 auto;
        background: #ffffff;
        padding: 2px 12px 14px;
        .listcontenthead{
            width: calc(100vw - 32px);
            margin-left: -10px;
            margin-bottom: 0px;
        }
        .listcontentmoney{
            display: flex;
            justify-content: space-between;
            p{
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
            }
            p:nth-child(1){
                color: #333333;
            }
            p:nth-child(2){
                color: #FF6400;
            }
            p:nth-child(3){
                color: #959595;
            }
        }
        .listcontent-list{
            width: 100%;
            background: #FFF8E9;
            border-radius: 10px;
            margin-top: 10px;
            .listcontent-list-head{
                width: 100%;
                display: flex;
                height: 45px;
                background: rgba(255, 225, 157, 1);
                border-radius: 10px 10px 0px 0px;
                .checkindex{
                    background: rgba(255, 248, 233, 1);
                    border-radius: 10px 10px 0 0;
                }
                div{
                    width: 100%;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #94540D;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .listcontent-list-lmy{
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        width: calc(100% - 4px);
        margin: 2px auto;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        background: rgba(255, 171, 47, 1);
        padding: 0 12px;
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            border:0;
            div{
                width: 52px;
                height: 60px;
                border:0;
                position: relative;
                text-align: center;
                img{
                    width: 36px;
                    margin-right: 12px;
                    position: relative;
                    margin: 0 auto;
                    border-radius: 50%;
                }
                img:nth-child(2){
                    width: 100%;
                    position: absolute;
                    bottom: 6px;
                }
                .paim{
                    width: 26px;
                    height: 14px;
                    background: #DDDDDD;
                    border-radius: 8px;
                    text-align: center;
                    line-height: 14px;
                    position: absolute;
                    bottom: 6px;
                    left: 13px;
                    font-size: 12px;
                    font-family: DINAlternate-Bold, DINAlternate;
                    font-weight: bold;
                    color: #666666;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
        .orgins{
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FF6400;
        }
    }
    .listcontent-list-list{
        width: 100%;
        padding: 0 14px;

        div:last-child{
            border:0;
        }
        div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            border-bottom: 1px solid rgba(247, 151, 0, 0.19);
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            div{
                border:0;
                div{
                    width: 52px;
                    border:0;
                    position: relative;
                    text-align: center;
                    img{
                        width: 36px;
                        margin-right: 12px;
                        position: relative;
                        margin: 0 auto;
                        border-radius: 50%;
                    }
                    img:nth-child(2){
                        width: 100%;
                        position: absolute;
                        bottom: 6px;
                    }
                    .paim{
                        width: 26px;
                        height: 14px;
                        background: #DDDDDD;
                        border-radius: 8px;
                        text-align: center;
                        line-height: 14px;
                        position: absolute;
                        bottom: 6px;
                        left: 13px;
                        font-size: 12px;
                        font-family: DINAlternate-Bold, DINAlternate;
                        font-weight: bold;
                        color: #666666;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            .orgins{
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FF6400;
            }
        }

    }
    .popupcontent{
        position: fixed;
        bottom: 0;
        width: 100%;
        background: rgba(254, 245, 193, 1);
        border-radius: 20px 20px 0 0 ;
        .popupcontent-listcheck{
            width: calc(100% - 20px);
            background: #FFFFFF;
            border-radius: 10px;
            margin: 14px auto 0;
            .popupcontent-listcheck-xian{
                width: calc(100% - 20px);
                height: 1px;
                background: #EEEEEE;
                margin: 0 auto;
            }
            .certificate{
                padding: 11px 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                p{
                    font-size: 16px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                }
                p:nth-child(2){
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #FF6400;
                    display: flex;
                    align-items: center;
                    span{
                        font-size: 30px;
                        font-family: DINOffcPro-Bold, DINOffcPro;
                        font-weight: bold;
                        color: #FF6400;
                        margin-top: -2px;
                    }
                }
            }
            .popupcontent-listcheck-head{
                display: flex;
                justify-content: space-between;
                padding: 10px;
                .popupcontent-listcheck-head-check{
                    border: 0;
                    background: rgba(255, 171, 47, 1);
                    p{
                        color: #ffffff;
                    }
                    p:nth-child(3){
                        background: rgba(255, 240, 217, 1);
                        img{
                            width: 13px;
                        }
                    }
                }
                div{
                    border-radius: 8px;
                    width: 100%;
                    margin: 0 4px;
                    border:1px solid #dddddd;
                    text-align: left;
                    padding-top: 14px;
                    padding-left: 12px;
                    padding-bottom: 34px;
                    position: relative;
                    box-sizing: border-box;
                    p{
                        font-size: 14px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                    }
                    p:nth-child(2){
                        position: absolute;
                        bottom: 0;
                        background: rgba(241, 241, 241, 0.5);
                        border-radius: 0px 0px 8px 8px;
                        width: 100%;
                        padding: 1px 0;
                        font-size: 12px;
                        left: 0;
                        text-align: center;
                    }
                    p:nth-child(3){
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 25px;
                        height: 20px;
                        background: #DDDDDD;
                        border-radius: 0px 7px 0px 7px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            width: 13px;
                        }
                    }
                }
            }
        }
        .popupcontent-head{
            text-align: center;
            background: url("https://ppyos.xijiuyou.com/202304/success-tips-bg-img.png") no-repeat;
            background-size: 100%;
            margin-top: -80px;
            padding-top:50px;
            position: relative;
            .popupcontent-btn{
                position: absolute;
                right: 12px;
                top: 78px;
                width: 24px;
            }
            p:nth-child(1){
                display: flex;
                justify-content: center;
                font-size: 20px;
                font-family: DINOffcPro-Bold, DINOffcPro;
                font-weight: bold;
                color: #FF4100;
                line-height: 18px;
                background: linear-gradient(180deg, #FF1677 0%, #FF7300 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                img{
                    width: 99px;
                    height: 20px;
                    margin-left: 4px;
                }
                span{
                    margin-top: 3px;
                }
                img:nth-child(1){
                    width: 38px;
                    height: 20px;
                    margin: 0;
                    margin-right: 4px;
                }
                margin-bottom: 19px;
            }
            img{
                width: 278px;
            }
            p:nth-child(3){
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #94540D;
                margin-top: 6px;
            }
        }
    }
    .checktable{
        width: calc(100% - 20px);
        margin: 10px auto 0;
        background: #ffffff;
        border-radius: 10px;
        padding:0 10px;
        .checktable-check:last-child{
            border:0;
        }
        .checktable-check{
            .caibei{
                width: 68px;
                height: 22px;
                background: linear-gradient(270deg, rgba(82, 162, 255, 0.5) 0%, rgba(154, 129, 255, 0.5) 100%);
                border-radius: 11px;
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                border:0;
            }
            display: flex;
            border-bottom: 1px solid #eeeeee;
            justify-content: space-between;
            padding: 20px 0;
            p{
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                img{
                    width: 18px;
                    margin-right: 6px;
                    margin-top: -2px;
                }
                span:nth-child(3){
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #999999;
                }
            }
            div{
                width: 22px;
                height: 22px;
                border: 1px solid #CCCCCC;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    width: 13px;
                }
            }
            .checktable-checkor{
                background: rgba(255, 195, 62, 1);
                border:0px;
            }
        }
    }
    .guize{
        display: flex;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        padding-left: 10px;
        margin:20px 0 18px 0;
        align-items: center;
        span{
            color: rgba(255, 100, 0, 1);
        }
        div{
            width: 12px;
            height: 12px;
            border:2px solid #eeeeee;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .bottom-zhif{
        width: 100%;
        height: 68px;
        background: #ffffff;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        align-items: center;
        img{
            width: 149px;
            height: 48px;
        }

        div{
            p{
                display: flex;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                align-items: center;
                span{
                    font-size: 24px;
                    font-family: DINOffcPro-Bold, DINOffcPro;
                    font-weight: bold;
                    color: #FF6400;
                    margin-top: -2px;
                }
                .yuan{
                    font-size: 18px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #FF6400;
                }
                img{
                    width: 16px;
                    height: 16px;
                    margin-left: 3px;
                    margin-top:-5px;
                }
            }
            p:nth-child(2){
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                line-height: 12px;
            }
        }
    }
    .showpopup{
        width: 294px;
        height: 277px;
        background: url("https://ppyos.xijiuyou.com/202304/big-bg-img.png") no-repeat;
        background-size: 100%;
        margin-top: 100px;
        .showpopuphead{
            width: 171px;
            margin-top: -31px;
        }
        div{
            margin-top: -35px;
            margin-bottom:20px;
            p:nth-child(1){
                font-size: 18px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
            }
            p:nth-child(2){
                font-size: 30px;
                font-family: DINAlternate-Bold, DINAlternate;
                font-weight: bold;
                color: #FF6400;
                margin: 0;
                span{
                    font-size: 14px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FF6400;
                }
            }
            p:nth-child(3){
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #999999;
                margin: 0;
            }
        }
        .showpopupbtn{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin: 30px 0;
            span{
                color: rgba(255, 100, 0, 1);
            }
        }
        .showpopupshib{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin: 40px 0;
        }
        .showpopupsucc{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            margin-top: 18px;
            margin-bottom: 23px;
            padding: 0 30px;
            span{
                color: rgba(255, 100, 0, 1);
            }
        }
        img{
            width: 187px;
        }
        p{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            margin-top: 9px;
        }
    }
}
.guide{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000;
    background: rgba(0,0,0,0.3);
    backdrop-filter: blur(10px);
    width: 100vw;
    height: 100vh;
    img{
        width: 100%;
    }
}
</style>
<style>
    .popupcontent-listcheck-head .caibei{
        font-size: 12px;
        display: block;
    }
    .popupcontent-listcheck-head .yuan{
        font-size: 12px;
        display: block;
    }
</style>