import axios from "axios";
import qs from'qs';
const clockin = {
  morningrecord(params) {
    return axios.get("/api/ppy/usercenter/morning/record",{params});
  },
  yesterdaySituation() {
    return axios.get("/api/ppy/usercenter/morning/yesterdaySituation");
  },
  YesterdayRankingList(params) {
    return axios.get("/api/ppy/usercenter/morning/yesterdayRankingList",{params});
  },
  todayInfo() {
    return axios.get("/api/ppy/usercenter/morning/todayInfo");
  },
  notice() {
    return axios.get("/api/ppy/usercenter/morning/notice");
  },
  morningsign() {
    return axios.post("/api/ppy/usercenter/morning/sign");
  },
  makeOrder(data) {
    return axios.post("/api/ppy/usercenter/morning/makeOrder",qs.stringify(data));
  },
  requestPay(data) {
    return axios.post("/api/ppy/usercenter/conch/requestPay",qs.stringify(data));
  },
  payInfo() {
    return axios.get("/api/ppy/usercenter/morning/payInfo");
  },
};
export default clockin;
